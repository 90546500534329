<template>
    <div class="center-course-order">
        <van-sticky>
            <van-tabs class="my-tabs" v-model="active" @change="changeType">
                <van-tab title="全部订单"></van-tab>
                <van-tab title="待支付"></van-tab>
                <van-tab title="已支付"></van-tab>
                <van-tab title="已关闭"></van-tab>
            </van-tabs>
        </van-sticky>

        <!-- 列表 -->
        <van-pull-refresh class="my-pull-refresh"  success-text="刷新成功" v-model="isLoading" @refresh="onRefresh">
            <van-list
                v-model="loading"
                :finished="finished"
                @load="onLoad1"
                class="my-list"
                >   
                <!-- 订单有三个状态 就是底部的按钮不一样 -->
                <div class="order-list">
                    <!-- 0、下单 1、已发起付款  2、已付款正常 8、订单关闭 9、已退款', -->
                    <div class="item" v-for="item in list" :key="item.id">
                        <div class="color" v-show="item.status === 0">等待买家付款</div>
                        <div class="color" v-show="item.status === 1">已发起付款</div>
                        <div class="color" v-show="item.status === 2">已支付</div>
                        <div class="color" v-show="item.status === 8">订单已关闭</div>
                        <van-card
                            :num="item.quantity"
                            :price="item.payAmount | price"
                            :desc="'使用余额: ￥'+item.balanceAmount"
                            :title="item.curriculumName"
                            :thumb="item.picUrl ? getImg(item.picUrl) : imgUrl1"
                        />
                        <!-- 待支付 -->
                        <div class="serve" v-show="item.status === 0">
                            <span>需付款</span>
                            <span class="money">
                                {{item.payAmount | totalPrice}}
                            </span>
                        </div>
                        <!-- 待发货 -->
                        <div class="serve" v-show="item.status === 2">
                            <span>实付款</span>
                            <span class="money">
                                {{item.payAmount | totalPrice}}
                            </span>
                        </div>
                        <!-- 待收货 -->
                        <div class="serve" v-show="item.status === 3">
                            <span>实付款</span>
                            <span class="money">
                                {{(item.price-0)*(item.quantity-0) | totalPrice}}
                            </span>
                        </div>
                        <div class="btn" v-show="item.status === 0 || item.status === 1">
                            <van-button type="default" @click="goClose(item)" round>去关单</van-button>
                            <van-button type="primary" @click="goPay(item)" round>去付款</van-button>
                        </div>
                    </div>
                </div>
                <no-more v-show="showNoMore && !loading"/>
            </van-list>
        </van-pull-refresh>
        <!-- 暂无数据 -->
        <no-data v-if="showData"/>
    </div>
</template>

<script>
import mixinPage from '@/utils/mixinPage'
export default {
    mixins: [ mixinPage ],
    data() {
        return {
            api: 'member/bill/curriculumOrderAndTime/curriculumOrder-findCurriculumOrderPage',
            active: null,
            imgUrl1: require('@/assets/img/horse1.jpg'),
            // orderList: [
            //     {price: '123.10', checked: false, count: 1, type: '1'},
            // ],
            model: {}
        }
    },
    filters: {
        totalPrice(value) {
            let totals = value.toFixed(2)
            return '￥' + totals;
        },
        price(val) {
            return (val-0).toFixed(2);
        }
    
    },
    methods: {
        init() {
            this.initList()
        },
        changeType() {
            this.judgeActive()
        },
        judgeActive() {
            if(this.active === 0){
                this.model ={}
            }
            if(this.active === 1) {
                this.model ={}
                this.$set(this.model, 'statusStart', 0)
                this.$set(this.model, 'statusEnd', 1)
            }
            if(this.active === 2) {
                this.model ={}
                this.$set(this.model, 'status', 2)
            }
            if(this.active === 3) {
                this.model ={}
                this.$set(this.model, 'status', 8)
            }
            this.init()
        },
        goClose(data) {
            //console.log(data)
            this.$router.push({path: '/center/students/close', query: {
                id: data.id
            }})
        },
        goPay(data) {
            this.$router.push({path: '/center/students/single', query: {
                id: data.id
            }})
        }
    },
    mounted() {
        this.active = (this.$route.query.type-0)
        // console.log(this.$route.query)
        this.judgeActive()
    }
}
</script>

<style lang="scss">
    @import '~@/assets/css/var';
    .center-course-order{
        .my-tabs{
            .van-tabs__line{
                width: 80px;
                background-color: $main;
            }
        }
        .order-list{
            .item{
                margin: 20px;
                border-radius: 19px;
                box-shadow: 0 0 6px 9px $boxShadow;
                background-color: #fff;
                padding-bottom: 10px;
                .color{
                    color: $red;
                    padding: 10px 10px 0 0;
                    text-align: right;
                }
            
                .van-card{
                    border-radius: 19px;
                }
                .serve{
                    text-align: right;
                    padding: 10px 20px;
           
                    .money{
                        font-size: 14px;
                    }
                }
                .btn{
                    padding: 10px 20px;
                    text-align: right;
                    border-radius: 0 0 19px 19px;
                    .van-button{
                        //back
                        height: 35px !important;
                        padding: 0 25px;
                    }
                    .van-button--default{
                        background-color: #fff;
                        color: #666;
                        border-color: #666;
                    }
                    .van-button--primary{
                        background-color: #fff;
                        color: $red;
                        border-color: $red;
                        margin-left: 20px;
                    }
                }

            }
        }
    }

</style>